import {
  SlickArrowPrev,
  SlickArrowNext,
} from "components/utils/SlickArrows/SlickArrows";
import { CartContext } from "pages/_app";
import { useContext } from "react";
import Slider from "react-slick";
import { replaceURL } from "../../utils/helpers";

export const ProductsCarousel = ({ products }) => {
  const { cart, setCart } = useContext(CartContext);

  const handleAddToCart = (id) => {
    const newProduct = products?.find((pd) => pd.id === id);
    setCart([...cart, { ...newProduct, quantity: 1 }]);
  };

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 1,
    prevArrow: <SlickArrowPrev />,
    nextArrow: <SlickArrowNext />,
    lazyLoad: "progressive",
    gap: "20px",
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
      <Slider {...settings}>
        {products.map((product) => (
          <Product
            addedInCart={Boolean(cart?.find((pd) => pd.id === product.id))}
            key={product._id}
            product={product}
            onAddToWish={(id) => console.log(id)}
            onAddToCart={handleAddToCart}
          />
        ))}
      </Slider>
    </div>
  );
};

const Product = ({ product, className }) => {
  const { name, price, slug, mainImage, discount } = product;
  return (
    <a
      href={`/product/${slug.current}`}
      className="most-viewed-wrapper"
      style={{ width: "100% !important" }}
    >
      <>
        <div className="single-product-detail most-viewed">
          <img src={replaceURL(mainImage)} alt={name} className="h-full" />
          {discount ? (
            <div
              className={`single-product-discount-badge`}
            >{`-${discount} %`}</div>
          ) : null}
        </div>
        {name && <p className="single-product-title">{name}</p>}
        <div className="single-product-price">
          {price && <p>₹{price} / Week</p>}
        </div>
      </>
    </a>
  );
};
