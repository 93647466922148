import client from '../../../client'
import axios from 'axios'

const API_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://www.jusplaytoys.com'

export const replaceURL = (url) => {
  return url?.replace(
    'https://jusplaytoys.fra1.digitaloceanspaces.com',
    'https://main-object-storage.blr1.cdn.digitaloceanspaces.com'
  )
}

export const sanityPagination = async (
  query,
  itemsPerPage = 18,
  currentPage = 1,
  order = 'desc' // pass desc or asc
) => {
  const startIdx = itemsPerPage * (currentPage - 1)
  const endIdx = startIdx + (itemsPerPage - 1)
  console.log(
    'sanity Query',
    `${query} | order(_createdAt ${order})[${startIdx}..${endIdx}]`
  )
  const data = await client.fetch(
    `${query} | order(_createdAt ${order})[${startIdx}..${endIdx}]`
  )
  return data
}

export const getUnixTimestamps = (lastHours = 24) => {
  const now = Math.floor(Date.now() / 1000) // Current timestamp (seconds)
  const last24Hours = now - lastHours * 60 * 60 // 24 hours ago
  return { from: last24Hours, to: now }
}

// Send order confirmation API Call to Business On Bot
export const sendBOBRequest = async (products, user, order, orderProducts) => {
  // orderProducts is to get the quantity of product ordered
  await axios.post(
    `${API_BASE_URL}/api/bob`,
    {
      products,
      user,
      order,
      orderProducts,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

/**
 * Function to validate request authorization and method.
 * Ensures that:
 * - The request has a valid "Authorization" header
 * - The request uses the correct HTTP method (e.g., GET)
 */
export const validateHeaderAndReqMethod = (
  req,
  res,
  allowedMethod = 'GET',
  headerKey
) => {
  const authHeader = req.headers['authorization']
  headerKey = headerKey || process.env[headerKey]

  if (!authHeader || authHeader !== headerKey) {
    res.status(401).json({ message: 'Unauthorized: Invalid Header' })
    return false // Prevent further execution
  }

  if (req.method !== allowedMethod) {
    res.status(405).json({ error: 'Method Not Allowed' })
    return false
  }

  return true
}
